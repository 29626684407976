
import {
	onMounted,
  reactive,
  ref,
  unref,
  toRefs,
  defineComponent,
  onUpdated,
} from "vue";
import { getUserPassQa, checkCompany, checkQa, repassword } from '@/apis/user'
import { useRouter, useRoute } from "vue-router";
import moment from "moment";
import { useI18n } from 'vue-i18n'
import i18n from '@/locales/index';
import { getLanguage } from '@/utils/cookies';
import { useStore } from '@/store';
import { ElForm, ElMessage } from 'element-plus';
let _t = i18n.global.t

export default {
  props: [],
  setup(props, context) {
    const enterpriseForm = ref(ElForm);
    const questionForm = ref(ElForm);
    const store = useStore()
    const language = getLanguage()
    const state = reactive({
      formQyh: { qyh: '' },
      formAqwt: { aqwt: '' },
      findKeyStep: 0,
      findVisable: false,
      question: '',
      hasQa: false,
      checkOldQa: '',
      chooseisAdmin: 1,
      enterpriseRules: {
        qyh: [{ validator: async(rule: any, value: any, callback: any) => {
          if (value === '') {
            callback(new Error(_t('system.forgetPassword.enterpriseEmpty')))
          } else {
            await checkCompany({username: 'admin', company_id: value}).then((res:any) => {
              if(res) {
                console.log(res,store.state.user.roles)
                if(res.code === 200){
                  state.hasQa = true
                  const lang=getLanguage();
                  switch(lang){
                    case 'es':
                      state.question=res.data.lists[0].content_es;
                      break;
                    case 'en':
                      state.question=res.data.lists[0].content_en;
                      break;
                    default:
                      state.question=res.data.lists[0].content_cn;
                      break;
                  }
                  //state.question = getLanguage() === 'en' ? res.data.lists[0].content_en : res.data.lists[0].content_cn
                  callback()
                } else if(res.code === 10003) {
                  state.hasQa = false 
                  callback()
                } else if(res.code === 40001) {
                  callback(new Error(_t('system.forgetPassword.enterpriseNoexistence')))
                } else {
                  callback(new Error(_t('system.forgetPassword.wrongEnterprise')))
                }
              }
            }).catch(() => {
              callback(new Error(_t('system.qa.failRequeat')))
            })
          }
        }, trigger: 'blur' }],
      },
      questionRules: {
        aqwt: [{ validator: async(rule: any, value: any, callback: any) => {
          if (value === '') {
            callback(new Error(_t('system.forgetPassword.answerEmpty')))
          } else {
            const newlist = [{
              question: state.question,
              answer: value
            }]
            const params = {
              username: 'admin',
              data: newlist,
              company_id: state.formQyh.qyh
            }
            await checkCompany(params).then((res: any) => {
              if(res) {
                if(res.code === 200) {
                  state.checkOldQa = res.data.token
                  callback()
                } else if(res.code === 40005) {
                  callback(new Error(_t('system.qa.answerError')))
                } else {
                  callback(new Error(res.msg))
                }
              }
            }).catch((error) => { console.log('error>>>>', error); callback(new Error(_t('system.qa.failRequeat'))) })
          }
        }, trigger: 'blur' }],
      },
      nextButton() {
        if(state.findKeyStep==0) { // 未设置
          if(state.chooseisAdmin === 1) {
            state.nextStep()
          } else {
            state.findKeyStep = 5
          }
        } else if(state.findKeyStep==1) { // 输企业号
          setTimeout(() => {
            unref(enterpriseForm).validate(async(valid: boolean) => {
              if (valid) {
                if(state.hasQa) {
                  state.nextStep()
                } else {
                  state.findKeyStep = 4
                }
              } else {
                return false
              }
            })
          }, 1000)
        } else if(state.findKeyStep==2) { // 回答问题
          setTimeout(() => {
            unref(questionForm).validate(async(valid: boolean) => {
              if (valid) {
                const params = { 
                  company_id: state.formQyh.qyh, 
                  username: 'admin',
                  password: '12345678',
                  confirm_password: '12345678'
                }
                repassword(params, state.checkOldQa).then((res:any) => {
                  if(res.code===200) {
                    state.nextStep()
                  } else {
                    ElMessage.warning(_t('system.forgetPassword.findPasswordError'))
                  }
                }).catch(error => ElMessage.warning(_t('system.forgetPassword.findPasswordError')))
              } else {
                return false
              }
            })
          }, 1000)
        } else {
          state.cancelfindKey()
        }
      },
      nextStep() {
        state.findKeyStep += 1
        if(state.findKeyStep===1 || state.findKeyStep===2) {
        }
      },
      cancelfindKey() {
        state.findKeyStep == 1 ? unref(enterpriseForm).resetFields() : 
        state.findKeyStep == 2 ? unref(questionForm).resetFields() : null
        state.formQyh = { qyh: '' }
        state.formAqwt = { aqwt: '' }
        state.findVisable = false
        state.findKeyStep = 0
        state.chooseisAdmin = 1
      },
    });
    onUpdated(() => {
    })
    return {
      ...toRefs(state),
      enterpriseForm,
      questionForm,
    }
  }
}
