<template>
    <div class="login-container">
        <el-form
            v-if="loginMethod == 'PASSWORD'"
            ref="loginFormRef"
            :model="loginForm"
            :rules="loginRules"
            class="login-form"
            autocomplete="on"
            label-position="left"
        >
            <img src="../../../assets/images/login/logo.png" class="logo" />
            <div class="title-container">
                <div class="loginType">
                    <span @click="changeLoginType('EMAIL')">{{ $t('user.loginEmail') }}</span>
                    <span class="on">{{ $t('user.loginPassword') }}</span>
                </div>
            </div>
            <el-form-item prop="company_id" class="loginFormItem">
                <span class="svg-container">
                    <i class="el-icon-office-building" />
                </span>
                <el-input
                    ref="userNameRef"
                    v-model="loginForm.company_id"
                    :placeholder="$t('login.company_id')"
                    name="company_id"
                    type="text"
                    tabindex="1"
                    autocomplete="on"
                />
            </el-form-item>
            <el-form-item prop="username" class="loginFormItem">
                <span class="svg-container">
                    <i class="el-icon-user" />
                </span>
                <el-input v-model="loginForm.username" :placeholder="$t('user.name')" name="username" type="text" tabindex="1" autocomplete="on" />
            </el-form-item>
            <el-tooltip v-model="capsTooltip" content="Caps lock is On" placement="right" manual>
                <el-form-item prop="password" class="loginFormItem">
                    <span class="svg-container">
                        <i class="el-icon-lock" />
                    </span>
                    <el-input
                        :key="passwordType"
                        ref="passwordRef"
                        v-model="loginForm.password"
                        :type="passwordType"
                        :placeholder="$t('login.password')"
                        name="password"
                        tabindex="2"
                        autocomplete="on"
                        @keyup="checkCapslock"
                        @blur="capsTooltip = false"
                        @keyup.enter="handleLogin"
                    />
                    <span class="show-pwd" @click="showPwd">
                        <svg-icon :name="passwordType === 'password' ? 'eye-off' : 'eye-on'" />
                    </span>
                </el-form-item>
            </el-tooltip>
            <div style="margin-top: -3px;">
                <FindKey />
            </div>

            <el-button
                :loading="loading"
                type="primary"
                style="width:100%; height:40px;margin-top: 10px"
                @click.prevent="handleLogin"
                @keyup.space.prevent.native
                @keydown.enter.prevent.native
            >
                {{ $t('login.logIn') }}
            </el-button>
        </el-form>

        <el-form v-else ref="loginEmailFormRef" :model="loginEmailForm" :rules="loginEmailRules" class="login-form" autocomplete="on" label-position="left">
            <img src="../../../assets/images/login/logo.png" class="logo" />
            <div class="step1" v-if="loginEmailStep == 'STEP1'">
                <div class="title-container">
                    <div class="loginType">
                        <span class="on">{{ $t('user.loginEmail') }}</span>
                        <span @click="changeLoginType('PASSWORD')">{{ $t('user.loginPassword') }}</span>
                    </div>
                </div>
                <el-form-item prop="company_id" class="loginFormItem">
                    <span class="svg-container">
                        <i class="el-icon-office-building" />
                    </span>
                    <el-input
                        ref="userNameRef"
                        v-model="loginEmailForm.company_id"
                        :placeholder="$t('login.company_id')"
                        name="company_id"
                        type="text"
                        tabindex="1"
                        autocomplete="on"
                    />
                </el-form-item>
                <el-form-item prop="email" class="loginFormItem">
                    <span class="svg-container">
                        <i class="el-icon-user" />
                    </span>
                    <el-input v-model="loginEmailForm.email" :placeholder="$t('user.email')" name="email" type="text" tabindex="1" autocomplete="on" />
                </el-form-item>
                <el-tooltip v-model="capsTooltip" content="Caps lock is On" placement="right" manual>
                    <el-form-item prop="password" class="loginFormItem">
                        <span class="svg-container">
                            <i class="el-icon-lock" />
                        </span>
                        <el-input
                            :key="passwordType"
                            ref="passwordEmailRef"
                            v-model="loginEmailForm.password"
                            :type="passwordType"
                            :placeholder="$t('login.password')"
                            name="password"
                            tabindex="2"
                            autocomplete="on"
                            @keyup="checkCapslock"
                            @blur="capsTooltip = false"
                            @keyup.enter="handleEmailLogin"
                        />
                        <span class="show-pwd" @click="showPwd">
                            <svg-icon :name="passwordType === 'password' ? 'eye-off' : 'eye-on'" />
                        </span>
                    </el-form-item>
                </el-tooltip>
                <div style="margin-top: -3px;">
                    <FindKey />
                </div>
                <el-button
                    :loading="loading"
                    type="primary"
                    style="width:100%; height:40px;margin-top: 10px"
                    @click.prevent="handleEmailLogin"
                    @keyup.space.prevent.native
                    @keydown.enter.prevent.native
                >
                    {{ $t('login.logIn') }}
                </el-button>
            </div>
            <div class="step2" v-else>
                <p>{{ $t('user.emailLogintip1') }}</p>
                <!-- <p>若未收到验证码，<span v-if="leftTime>0"><span>{{leftTime}}s </span>后</span>可<el-link :type="leftTime==0 ? 'primary':'default'" :disabled="leftTime!=0" @click.prevent="reSendEmail">重发</el-link></p> -->
                <p>
                    <span v-html="countdownTips"></span>
                    <el-link :type="leftTime == 0 ? 'primary' : 'default'" :disabled="leftTime != 0" @click.prevent="reSendEmail">
                        【{{ $t('user.resend') }}】
                    </el-link>
                </p>
                <el-form-item class="loginFormItem">
                    <el-input v-model="emailVerifyCode" name="emailVerifyCode" type="text" />
                </el-form-item>
                <el-checkbox v-model="isRemember" :label="$t('user.noverifyday30')" />
                <div style="clear:both; padding-top:30px; text-align: center;">
                    <el-button style="width:70%;" :type="emailVerifyCode == '' ? 'info' : 'primary'" :disabled="emailVerifyCode == ''" @click="verifyEmail">
                        {{ $t('user.loginConfirm') }}
                    </el-button>
                </div>
            </div>
        </el-form>
        <div class="footer_login">
            <p>
                <span>{{ $t('login.loginAgree') }}</span>
                <span>
                    <Agreement />
                </span>
            </p>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, watch, ref, nextTick, toRefs } from 'vue';
import LangSelect from '@/components/lang_select/Index.vue';
import FindKey from '@/components/find-key/Index.vue';
import { isValidUsername } from '@/utils/validate';
import { useRoute, LocationQuery, useRouter } from 'vue-router';
import { useStore } from '@/store';
import { UserActionTypes } from '@/store/modules/user/action-types';
import Agreement from './Agreement.vue';
import i18n from '@/locales/index';
import { getRemember, setRemember } from '@/utils/cookies';
import { ElMessage } from 'element-plus';
import https from '@/utils/https';
import { RootObject } from '@/model/rootObject';
import { LoginModel } from './model/loginModel';
import { ContentType, Method } from 'axios-mapper';
export default defineComponent({
    components: {
        LangSelect,
        Agreement,
        FindKey
    },
    setup() {
        const userNameRef = ref(null);
        const passwordRef = ref(null);
        const loginFormRef = ref(null);
        const router = useRouter();
        const route = useRoute();
        const store = useStore();

        //
        const loginMethod = ref<string>('PASSWORD');
        const loginEmailFormRef = ref(null);
        const validateEmail = (rule, value, callback) => {
            if (value === '') callback(new Error(i18n.global.t('email.placeHolder')));
            else if (
                !/^[a-zA-Z0-9\u4e00-\u9fa5\u00C0-\u024F](([_-]|\.)?[a-zA-Z0-9\u4e00-\u9fa5\u00C0-\u024F]+)+@[a-zA-Z0-9]+([_-]?[a-zA-Z0-9]+)?(\.[a-zA-Z0-9]+([_-]?[a-zA-Z0-9]+)?)+$/.test(
                    value
                )
            )
                callback(new Error(i18n.global.t('user.emailRule2')));
            else callback();
        };
        // 邮箱登录步骤1【STEP1】 输入信息进行验证
        // 邮箱登录步骤2【STEP2】 需要输入验证码验证
        const loginEmailStep = ref('STEP1');
        const emailVerifyCode = ref('');
        const isRemember = ref(false);
        const countdownTips = ref('');
        let timer = 0;
        const leftTime = ref(0);
        //
        // const { t } = useI18n()
        const state = reactive({
            loginForm: {
                username: '',
                password: '',
                company_id: ''
            },
            loginRules: {
                company_id: [
                    {
                        required: true,
                        message: i18n.global.t('login.tip_company'),
                        trigger: 'blur'
                    }
                ],
                username: [
                    {
                        required: true,
                        message: i18n.global.t('user.placeHolder'),
                        trigger: 'blur'
                    }
                ],
                password: [
                    {
                        required: true,
                        message: i18n.global.t('login.tip_pwd'),
                        trigger: 'blur'
                    }
                ]
            },
            passwordType: 'password',
            loading: false,
            showDialog: false,
            capsTooltip: false,
            redirect: '',
            otherQuery: {},
            loginEmailForm: {
                email: '',
                password: '',
                company_id: '',
                need: false,
                code: '',
                remember: false
            },
            loginEmailRules: {
                company_id: [
                    {
                        required: true,
                        message: i18n.global.t('login.tip_company'),
                        trigger: 'blur'
                    }
                ],
                email: [{ required: true, validator: validateEmail, trigger: 'blur' }],
                password: [
                    {
                        required: true,
                        message: i18n.global.t('login.tip_pwd'),
                        trigger: 'blur'
                    }
                ]
            }
        });

        const methods = reactive({
            changeLoginType: (type: string) => {
                state.loginEmailForm = {
                    email: '',
                    password: '',
                    company_id: '',
                    need: false,
                    code: '',
                    remember: false
                };
                state.loginForm = {
                    username: '',
                    password: '',
                    company_id: ''
                };
                loginMethod.value = type;
            },
            validateUsername: (rule: any, value: string, callback: Function) => {
                if (!isValidUsername(value)) {
                    callback(new Error('Please enter the correct user name'));
                } else {
                    callback();
                }
            },
            validatePassword: (rule: any, value: string, callback: Function) => {
                if (value.length < 6) {
                    callback(new Error('The password can not be less than 6 digits'));
                } else {
                    callback();
                }
            },
            checkCapslock: (e: KeyboardEvent) => {
                const { key } = e;
                state.capsTooltip = key !== null && key.length === 1 && key >= 'A' && key <= 'Z';
            },
            showPwd: () => {
                if (state.passwordType === 'password') {
                    state.passwordType = '';
                } else {
                    state.passwordType = 'password';
                }
                nextTick(() => {
                    (passwordRef.value as any).focus();
                });
            },
            handleLogin: () => {
                (loginFormRef.value as any).validate(async (valid: boolean) => {
                    if (valid) {
                        state.loading = true;
                        await store.dispatch(UserActionTypes.ACTION_LOGIN, state.loginForm);
                        router
                            .push({
                                path: state.redirect || '/',
                                query: state.otherQuery
                            })
                            .catch((err) => {
                                console.warn(err);
                            });
                        // Just to simulate the time of the request
                        setTimeout(() => {
                            state.loading = false;
                        }, 0.5 * 1000);
                    } else {
                        return false;
                    }
                });
            },
            handleEmailLogin: () => {
                (loginEmailFormRef.value as any).validate(async (valid: boolean) => {
                    if (!valid) return false;
                    https(false)
                        .request<RootObject<LoginModel>>(
                            'email_login',
                            Method.POST,
                            {
                                email: state.loginEmailForm.email,
                                company_id: state.loginEmailForm.company_id,
                                password: state.loginEmailForm.password,
                                need: getRemember(state.loginEmailForm.company_id, state.loginEmailForm.email) ? false : true,
                                code: ''
                            },
                            ContentType.json
                        )
                        .then(async (res) => {
                            switch (res?.code) {
                                case 200:
                                    if (res.data && res.data.token) {
                                        console.log('login success');
                                        await store.dispatch(UserActionTypes.ACTION_LOGIN_EMAIL, {
                                            company_id: state.loginEmailForm.company_id,
                                            email: state.loginEmailForm.email,
                                            remember: false,
                                            token: res.data.token
                                        });
                                        router
                                            .push({
                                                path: state.redirect || '/',
                                                query: state.otherQuery
                                            })
                                            .catch((err) => {
                                                console.log(err);
                                            });
                                    } else {
                                        loginEmailStep.value = 'STEP2';
                                        methods.countDown();
                                    }
                                    break;
                                case 400:
                                    ElMessage({
                                        message: i18n.global.t('global.error400'),
                                        type: 'error',
                                        duration: 1e3 * 5
                                    });
                                    break;
                                case 30002:
                                    ElMessage({
                                        message: i18n.global.t('global.error30002'),
                                        type: 'error',
                                        duration: 1e3 * 5
                                    });
                                    break;
                                case 40001:
                                    ElMessage({
                                        message: i18n.global.t('system.companyNoExis'),
                                        type: 'error',
                                        duration: 1e3 * 5
                                    });
                                    break;
                                case 40002:
                                    ElMessage({
                                        message: i18n.global.t('system.userNoExis'),
                                        type: 'error',
                                        duration: 1e3 * 5
                                    });
                                    break;
                                case 40003:
                                    ElMessage({
                                        message: i18n.global.t('global.error40003'),
                                        type: 'error',
                                        duration: 1e3 * 5
                                    });
                                    break;
                                case 400031:
                                    ElMessage({
                                        type: 'error',
                                        duration: 1e3 * 5,
                                        message: i18n.global.t('system.passwordNoExis').replace('N', '1')
                                    });
                                    break;
                                case 400032:
                                    ElMessage({
                                        type: 'error',
                                        duration: 1e3 * 5,
                                        message: i18n.global.t('system.passwordNoExis').replace('N', '2')
                                    });
                                    break;
                                case 400033:
                                    ElMessage({
                                        type: 'error',
                                        duration: 1e3 * 5,
                                        message: i18n.global.t('system.passwordNoExis').replace('N', '3')
                                    });
                                    break;
                                case 400034:
                                    ElMessage({
                                        type: 'error',
                                        duration: 1e3 * 5,
                                        message: i18n.global.t('system.passwordNoExis').replace('N', '4')
                                    });
                                    break;
                                case 400035:
                                    ElMessage({
                                        type: 'error',
                                        duration: 1e3 * 5,
                                        message: i18n.global.t('system.passwordNoExis').replace('N', '5')
                                    });
                                    break;
                                case 40007:
                                    ElMessage({
                                        message: i18n.global.t('user.DisableUser'),
                                        type: 'error',
                                        duration: 1e3 * 5
                                    });
                                    break;
                                case 40008:
                                    ElMessage({
                                        message: i18n.global.t('global.error40008'),
                                        type: 'error',
                                        duration: 1e3 * 5
                                    });
                                    break;
                                case 40009:
                                    ElMessage({
                                        message: i18n.global.t('global.error40009'),
                                        type: 'error',
                                        duration: 1e3 * 5
                                    });
                                    break;
                                default:
                                    console.log('===============UNKNOWN==================');
                                    break;
                            }
                        })
                        .catch((err) => {
                            console.warn(err);
                        });
                });
            },
            verifyEmail: async () => {
                https(false)
                    .request<RootObject<LoginModel>>(
                        'email_login',
                        Method.POST,
                        {
                            email: state.loginEmailForm.email,
                            company_id: state.loginEmailForm.company_id,
                            password: state.loginEmailForm.password,
                            need: true,
                            code: emailVerifyCode.value
                        },
                        ContentType.json
                    )
                    .then(async (res) => {
                        switch (res?.code) {
                            case 200:
                                if (res.data && res.data.token) {
                                    console.log('login success');
                                    await store.dispatch(UserActionTypes.ACTION_LOGIN_EMAIL, {
                                        company_id: state.loginEmailForm.company_id,
                                        email: state.loginEmailForm.email,
                                        remember: isRemember.value,
                                        token: res.data.token
                                    });
                                    router
                                        .push({
                                            path: state.redirect || '/',
                                            query: state.otherQuery
                                        })
                                        .catch((err) => {
                                            console.log(err);
                                        });
                                } else {
                                    console.log(res);
                                }
                                break;
                            case 400:
                                ElMessage({
                                    message: i18n.global.t('global.error400'),
                                    type: 'error',
                                    duration: 1e3 * 5
                                });
                                break;
                            case 30002:
                                ElMessage({
                                    message: i18n.global.t('global.error30002'),
                                    type: 'error',
                                    duration: 1e3 * 5
                                });
                                break;
                            case 40001:
                                ElMessage({
                                    message: i18n.global.t('system.companyNoExis'),
                                    type: 'error',
                                    duration: 1e3 * 5
                                });
                                break;
                            case 40002:
                                ElMessage({
                                    message: i18n.global.t('system.userNoExis'),
                                    type: 'error',
                                    duration: 1e3 * 5
                                });
                                break;
                            case 40003:
                                ElMessage({
                                    message: i18n.global.t('global.error40003'),
                                    type: 'error',
                                    duration: 1e3 * 5
                                });
                                break;
                            case 40007:
                                ElMessage({
                                    message: i18n.global.t('user.DisableUser'),
                                    type: 'error',
                                    duration: 1e3 * 5
                                });
                                break;
                            case 40008:
                                ElMessage({
                                    message: i18n.global.t('global.error40008'),
                                    type: 'error',
                                    duration: 1e3 * 5
                                });
                                break;
                            case 40009:
                                ElMessage({
                                    message: i18n.global.t('global.error40009'),
                                    type: 'error',
                                    duration: 1e3 * 5
                                });
                                break;
                            default:
                                console.log('===============UNKNOWN==================');
                                break;
                        }
                    })
                    .catch((err) => {
                        console.warn(err);
                    });
            },
            reSendEmail: async () => {
                https(false)
                    .request<RootObject<LoginModel>>(
                        'email_login',
                        Method.POST,
                        {
                            email: state.loginEmailForm.email,
                            company_id: state.loginEmailForm.company_id,
                            password: state.loginEmailForm.password,
                            need: true,
                            code: ''
                        },
                        ContentType.json
                    )
                    .then(async (res) => {
                        switch (res?.code) {
                            case 200:
                                loginEmailStep.value = 'STEP2';
                                ElMessage({
                                    message: i18n.global.t('user.tipsresendemail'),
                                    type: 'success',
                                    duration: 1e3 * 5
                                });
                                methods.countDown();
                                break;
                            case 400:
                                ElMessage({
                                    message: i18n.global.t('global.error400'),
                                    type: 'error',
                                    duration: 1e3 * 5
                                });
                                break;
                            case 30002:
                                ElMessage({
                                    message: i18n.global.t('global.error30002'),
                                    type: 'error',
                                    duration: 1e3 * 5
                                });
                                break;
                            case 40001:
                                ElMessage({
                                    message: i18n.global.t('system.companyNoExis'),
                                    type: 'error',
                                    duration: 1e3 * 5
                                });
                                break;
                            case 40002:
                                ElMessage({
                                    message: i18n.global.t('system.userNoExis'),
                                    type: 'error',
                                    duration: 1e3 * 5
                                });
                                break;
                            case 40003:
                                ElMessage({
                                    message: i18n.global.t('global.error40003'),
                                    type: 'error',
                                    duration: 1e3 * 5
                                });
                                break;
                            case 40007:
                                ElMessage({
                                    message: i18n.global.t('user.DisableUser'),
                                    type: 'error',
                                    duration: 1e3 * 5
                                });
                                break;
                            case 40008:
                                ElMessage({
                                    message: i18n.global.t('global.error40008'),
                                    type: 'error',
                                    duration: 1e3 * 5
                                });
                                break;
                            case 40009:
                                ElMessage({
                                    message: i18n.global.t('global.error40009'),
                                    type: 'error',
                                    duration: 1e3 * 5
                                });
                                break;
                            default:
                                console.log('===============UNKNOWN==================');
                                break;
                        }
                    })
                    .catch((err) => {
                        console.warn(err);
                    });
            },
            countDown() {
                clearInterval(timer);
                leftTime.value = 30;
                countdownTips.value = i18n.global.t('user.emailLogintip2').replace('%d', `<span class="cd">${leftTime.value}</span>`);
                timer = window.setInterval(() => {
                    leftTime.value = leftTime.value - 1;
                    countdownTips.value = i18n.global.t('user.emailLogintip2').replace('%d', `<span class="cd">${leftTime.value}</span>`);
                    if (leftTime.value < 1) {
                        clearInterval(timer);
                    }
                }, 1e3);
            }
        });

        function getOtherQuery(query: LocationQuery) {
            return Object.keys(query).reduce((acc, cur) => {
                if (cur !== 'redirect') {
                    acc[cur] = query[cur];
                }
                return acc;
            }, {} as LocationQuery);
        }

        watch(
            () => route.query,
            (query) => {
                if (query) {
                    state.redirect = query.redirect?.toString() ?? '';
                    state.otherQuery = getOtherQuery(query);
                }
            }
        );
        return {
            userNameRef,
            passwordRef,
            loginFormRef,
            ...toRefs(state),
            ...toRefs(methods),
            // t
            loginMethod,
            loginEmailFormRef,
            loginEmailStep,
            emailVerifyCode,
            isRemember,
            leftTime,
            countdownTips
        };
    }
});
</script>

<style lang="scss">
// References: https://www.zhangxinxu.com/wordpress/2018/01/css-caret-color-first-line/
@supports (-webkit-mask: none) and (not (cater-color: $loginCursorColor)) {
    .login-container .el-input {
        input {
            color: #000;
        }
    }
}

.login-container {
    background: #f1f2f6 url('../../../assets/images/login/login.svg');
    background-size: contain;

    .loginFormItem {
        border: 1px solid rgba(255, 255, 255, 0.1);
        background: #fff;
        border-radius: 5px;

        .el-input {
            display: inline-block;
            height: 47px;
            width: 85%;
        }

        input {
            height: 47px;
            background: transparent;
            border: 0px;
            border-radius: 0px;
            padding: 12px 5px 12px 15px;
            // color: #000;
            // caret-color: $loginCursorColor;
            -webkit-appearance: none;

            &:-webkit-autofill {
                box-shadow: 0 0 0px 1000px #fff inset !important;
                -webkit-text-fill-color: #000 !important;
            }
        }

        input::-webkit-input-placeholder {
            /* WebKit browsers */
            color: #ccc !important;
        }

        input:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: #ccc;
        }

        input::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: #ccc;
        }

        input:-ms-input-placeholder {
            /* Internet Explorer 10+ */
            color: #ccc;
        }
    }
}

.step2 {
    padding: 40px 30px 0 30px;

    p {
        font-size: 14px;

        > span > span {
            color: #1890ff;
        }
    }
}
</style>

<style lang="scss" scoped>
.login-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: auto;
    background-color: #f0f2f5;

    // overflow: hidden;
    // background-color: $loginBg;
    .login-form {
        position: relative;
        width: 520px;
        max-width: 100%;
        padding: 230px 35px 0;
        margin: 0 auto;
    }

    .tips {
        font-size: 14px;
        color: #fff;
        margin-bottom: 10px;

        span {
            &:first-of-type {
                margin-right: 16px;
            }
        }
    }

    .svg-container {
        padding: 6px 5px 6px 15px;
        color: #000;
        vertical-align: middle;
        width: 30px;
        display: inline-block;
    }

    .title-container {
        position: relative;
        text-align: center;

        .title {
            display: inline-block;
            padding: 0 6px 10px;
            // border-bottom: 2px solid #1890ff;
            text-align: center;
            font-size: 14px;
            color: #1890ff;
            font-weight: bold;
            margin: 40px auto;
        }

        .set-language {
            color: #000;
            position: absolute;
            top: 26px;
            font-size: 18px;
            right: 0px;
            cursor: pointer;
        }

        .loginType {
            margin: 40px 0 30px;
            padding: 0 30px;

            span {
                position: relative;
                display: inline-block;
                width: 50%;
                text-align: center;
                cursor: pointer;
                color: #999;

                &.on {
                    color: #000;

                    &::after {
                        content: ' ';
                        display: block;
                        position: absolute;
                        height: 2px;
                        background-color: #1890ff;
                        bottom: -7px;
                        width: 100%;
                        transform: scaleX(0.58);
                    }
                }
            }
        }
    }

    .show-pwd {
        position: absolute;
        right: 10px;
        top: 7px;
        font-size: 16px;
        color: #000;
        cursor: pointer;
        user-select: none;
    }

    .thirdparty-button {
        position: absolute;
        right: 0;
        bottom: 6px;
    }

    .logo {
        margin-left: -20px;
    }

    .footer_login {
        margin-top: 100px;
        text-align: center;
        color: #666;

        p {
            margin-bottom: 6px;
            font-size: 14px;
        }
    }

    @media only screen and (max-width: 470px) {
        .thirdparty-button {
            display: none;
        }
    }
}
</style>
