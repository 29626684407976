<!--
 * @Descripttion: 
 * @Author: zhongbin.chen
 * @Date: 2021-12-10 10:27:15
-->
<template>
  <div class="agreement">
    <span class="titleName" @click="visiblefn">{{$t("login.termsService")}}</span>
    <!-- <el-dialog top="30px" title v-model="visible" width="832px">
      <div class="agreementInfo">
        <div class="contentBox">
          <p class="text1 center fontbold" style="margin-bottom: 30px">{{$t('agreement.text1')}}</p>
          <p class="text2" style="margin-bottom: 10px">{{$t('agreement.text2')}}</p>
          <li>{{$t('agreement.text3')}}<span class="fontbold">{{$t('agreement.text3-1')}}</span></li>
          <li>{{$t('agreement.text4')}}</li>
          <li style="margin-bottom: 20px">{{$t('agreement.text5')}}</li>

          <p
            style="margin-bottom: 20px"
          >{{$t('agreement.text6')}}</p>

          <p style="margin-bottom: 20px">
            {{$t('agreement.text7')}}<span class="fontbold">{{$t('agreement.text7-1')}}</span>{{$t('agreement.text7-2')}}<span class="fontbold">{{$t('agreement.text7-3')}}</span>{{$t('agreement.text7-4')}}
          </p>

          <p class="fontbold" style="margin-bottom: 20px">
            <span class="fontbold">{{$t('agreement.text8')}}</span>{{$t('agreement.text8-1')}}<span class="fontbold">{{$t('agreement.text8-2')}}</span>{{$t('agreement.text8-3')}}
          </p>

          <p>{{$t('agreement.text9')}}</p>
          <p>
            {{$t('agreement.text10')}}
            <span class="fontbold">{{$t('agreement.text10-1')}}</span>
          </p>
          <p>{{$t('agreement.text11')}}</p>
          <p>
            {{$t('agreement.text12')}}<span class="fontbold">{{$t('agreement.text12-1')}}</span>{{$t('agreement.text12-3')}}<span class="fontbold">{{$t('agreement.text12-4')}}</span>
          </p>
          <p class="fontbold" style="margin-bottom: 20px">{{$t('agreement.text13')}}</p>

          <p class="fontbold">{{$t('agreement.text14')}}</p>
          <p>
            <span class="fontbold">{{$t('agreement.text15')}}</span>
            {{$t('agreement.text15-1')}}
          </p>
          <p class="fontbold">{{$t('agreement.text16')}}</p>
          <p class="fontbold">{{$t('agreement.text17')}}</p>
          <p class="fontbold" style="margin-bottom: 20px">{{$t('agreement.text18')}}</p>

          <p class="fontbold">{{$t('agreement.text19')}}</p>
          <p>{{$t('agreement.text20')}}</p>
          <p>{{$t('agreement.text21')}}</p>
          <p style="margin-bottom: 20px">{{$t('agreement.text22')}}</p>

          <p class="fontbold">{{$t('agreement.text23')}}</p>
          <p>{{$t('agreement.text24')}}</p>
          <p class="fontbold">{{$t('agreement.text25')}}</p>
          <p class="fontbold">{{$t('agreement.text26')}}</p>
          <p class="fontbold">{{$t('agreement.text27')}}</p>
          <p class="fontbold">{{$t('agreement.text28')}}</p>
          <p class="fontbold">{{$t('agreement.text29')}}</p>
          <p class="fontbold">{{$t('agreement.text30')}}</p>
          <p class="fontbold">{{$t('agreement.text31')}}</p>
          <p class="fontbold" style="margin-bottom: 20px">{{$t('agreement.text32')}}</p>

          <p class="fontbold"
            style="margin-bottom: 20px"
          >{{$t('agreement.text33')}}</p>

          <p class="fontbold" style="margin-bottom: 20px">{{$t('agreement.text34')}}</p>

          <p class="fontbold"
            style="margin-bottom: 20px"
          >{{$t('agreement.text35')}}</p>

          <p class="fontbold">{{$t('agreement.text36')}}</p>
          <p>{{$t('agreement.text37')}}</p>
          <p>{{$t('agreement.text38')}}</p>
        </div>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
  ref,
  nextTick,
  toRefs
} from "vue";
import { getprivacy } from "@/apis/user";
import { getLanguage } from "@/utils/cookies";
export default {
  components: {},
  setup() {
    const state = reactive({
      visible: false
    });
    const method = reactive({
      visiblefn() {
        window.open('https://adibot.com/adibot-privacy-policy/');
        // if (process.env.VUE_APP_IS_NA && process.env.VUE_APP_IS_NA == "yes") {
        //   window.open('https://adibot.com/adibot-privacy-policy/');
        // } else {
        //   getprivacy().then(res => {
        //     console.log(res.data[0].slice(-6, -4));
        //     // const pafurl = (res.data[0].slice(-6,-4) = 'EN'?res.data[0]:res.data[0])
        //     let ENpafurl = "";
        //     let CNpafurl = "";
        //     if (res.data[0].slice(-6, -4) == "EN") {
        //       ENpafurl = res.data[0];
        //       CNpafurl = res.data[1];
        //       //  console.log(ENpafurl)
        //     } else {
        //       ENpafurl = res.data[1];
        //       CNpafurl = res.data[0];
        //     }
        //     if (getLanguage() == "zh-cn") {
        //       window.open(CNpafurl);
        //     } else {
        //       window.open(ENpafurl);
        //     }
        //   });
        // }
      }
    });
    onMounted(() => {});
    return {
      ...toRefs(state),
      ...toRefs(method)
    };
  }
};
</script>

<style lang="scss" scoped>
.agreement {
  display: inline-block;

  .fontbold {
    font-weight: 600;
  }

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0;
  }

  .titleName {
    color: #40a9ff;
    cursor: pointer;
  }

  .agreementInfo {
    height: 800px;
    overflow-y: auto;
  }

  .contentBox {
    padding: 50px 34px;
    text-align: left;

    .center {
      text-align: center;
    }

    p,
    li {
      line-height: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: rgba(0, 0, 0, 0.65);
      margin: 0 0 4px;

      span {
        color: rgba(0, 0, 0, 0.65);
      }
    }

    .text1 {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      color: #000;
    }

    .text2 {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      color: #000;
    }

    .closeBtn {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }
  }
}
</style>
